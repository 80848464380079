import { ref, computed, nextTick } from '@vue/composition-api'

// Notification
import dayjs from 'dayjs'
import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import store from '@/store'
import {
  transformDatetimeToHumanReadablePretty,
  transformDateToHumanReadablePretty,
  transformTimeToHumanReadablePretty,
} from '@/helpers/dateTimeHelper'

export default (props, { root }) => {
  const refScriptListTable = ref(null)

  const loading = ref(false)
  const perPage = ref(10)
  const items = ref(0)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('startAt')
  const dateRange = ref([])
  const dateRangeString = ref([])
  const isSortDirDesc = ref(false)
  const calendarEventToCancel = ref(null)

  const list = ref([])
  const dataMeta = computed(() => ({
    from: perPage.value * (currentPage.value - 1) + (totalItems.value ? 1 : 0),
    to: perPage.value * (currentPage.value - 1) + items.value,
    of: totalItems.value,
  }))

  // watch([currentPage, perPage, searchQuery], () => {
  //   refetchData()
  // })

  const apiSortColumnMapper = {
    startAt: 'ce.startAt',
  }

  const resetDateRange = () => {
    const defaultDates = [
      dayjs().subtract(7, 'day').toDate(),
      dayjs().add(28, 'day').toDate(),
    ]
    dateRange.value = defaultDates
    dateRangeString.value = defaultDates
  }
  resetDateRange()

  const fetchData = () => {
    loading.value = true
    nextTick(() => {
      store.dispatch('customers/fetchCustomerCalendarEvents', {
        params: {
          limit: perPage.value,
          offset: (currentPage.value - 1) * perPage.value,
          orderBy: apiSortColumnMapper[sortBy.value],
          orderDir: isSortDirDesc.value ? 'desc' : 'asc',
          from: dayjs(dateRange.value[0]).startOf('day').format(),
          to: dayjs(dateRange.value[1]).endOf('day').format(),
        },
      })
        .then(res => {
          list.value = res.data.items
          items.value = res.data.filtered
          totalItems.value = res.data.total
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  const configDateTimePicker = {
    locale: PolishLocale,
    disableMobile: true,
    // altFormat: 'd.m.Y',
    dateFormat: 'd.m.Y',
    mode: 'range',
    onClose: dates => {
      if (dates.length === 2) {
        dateRange.value = dates
      } else {
        resetDateRange()
      }
      fetchData()
    },
  }

  const getPaymentStatus = event => {
    const paymentStatus = { paid: 0, total: 0 }

    Object.values(event.calendarEventCustomers).forEach(calendarEventCustomer => {
      paymentStatus.total += parseFloat(calendarEventCustomer.settlement.amount)
      if (calendarEventCustomer.settlement.paid) {
        paymentStatus.paid += parseFloat(calendarEventCustomer.settlement.amount)
      }
    })

    return paymentStatus
  }

  const cancelMeetingHandler = calendarEvent => {
    calendarEventToCancel.value = calendarEvent
    nextTick(() => {
      root.$emit('bv::show::modal', 'cancel-event-modal-by-customer')
    })
  }

  const cancelledEventHandler = () => {
    fetchData()
  }

  const isPossibleToCancel = calendarEvent => {
    if (dayjs().isAfter(dayjs(calendarEvent.startAt))) {
      return false
    }

    return Object.values(calendarEvent.calendarEventCustomers)
      .some(calendarEventCustomer => calendarEventCustomer.customer.canCancelReservations)
  }

  fetchData()

  return {
    loading,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    dateRange,
    dateRangeString,
    isSortDirDesc,
    refScriptListTable,
    configDateTimePicker,
    fetchData,
    list,
    calendarEventToCancel,
    getPaymentStatus,
    cancelMeetingHandler,
    cancelledEventHandler,
    isPossibleToCancel,
    transformDateToHumanReadablePretty,
    transformDatetimeToHumanReadablePretty,
    transformTimeToHumanReadablePretty,
  }
}
