<template>
  <div>
    <b-modal
      v-if="calendarEvent"
      :id="id"
      ref="cancelEventByCustomerModal"
      v-model="isShowingModal"
      :title="$t(`calendar.event.cancellation-modal.title`)"
      :cancel-title="$t('shared.btn.cancel')"
      cancel-variant="outline-secondary"
      ok-variant="danger"
      :ok-title="$t(`calendar.event.cancellation-modal.confirm-button-text`)"
      :ok-disabled="processing || calendarEventCustomersToCancel.length === 0"
      @ok="cancelEvent"
      @show="init"
      @hidden="$emit('hidden')"
    >
      <form
        id="cancel-event-modal-form"
        autocomplete="off"
        @submit.stop.prevent
      >
        <b-row
          class="mb-50"
        >
          <b-col>
            <dl
              v-if="service"
              class="row mb-0"
            >
              <dt class="col-sm-5">
                {{ $t('calendar.event.cancellation-modal.service-name') }}:
              </dt>
              <dd class="col-sm-7">
                {{ service.name }}
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-5">
                {{ $t('calendar.event.cancellation-modal.event-time') }}:
              </dt>
              <dd class="col-sm-7">
                {{ calendarEvent.startAt | transformDatetimeToHumanReadablePretty }}
              </dd>
            </dl>
            <dl
              v-if="calendarEventCustomersToCancel.length"
              v-acl="[role.guardian]"
              class="row mb-0"
            >
              <dt class="col-sm-5">
                {{ $tc('shared.plural.attendees-title', calendarEventCustomersToCancel.length) }}:
              </dt>
              <dd class="col-sm-7">
                <p
                  v-for="calendarEventCustomerToCancel in calendarEventCustomersToCancel"
                  :key="`customer-to-cancel-${calendarEventCustomerToCancel.id}`"
                  class="mb-0"
                >
                  {{ calendarEventCustomerToCancel.customer.lastName }}
                  {{ calendarEventCustomerToCancel.customer.firstName }}
                </p>
              </dd>
            </dl>
          </b-col>
        </b-row>
        <b-row
          v-if="calendarEventCustomersThatCanCancel.length > 1"
          class="mb-50"
        >
          <b-col>
            <b-form-group
              v-slot="{ ariaDescribedby }"
              :label="$t('calendar.event.cancellation-modal.list-of-absentees')"
            >

              <b-form-checkbox
                v-for="calendarEventCustomer in calendarEventCustomersThatCanCancel"
                :key="`calendar-event-customer-${calendarEventCustomer.id}`"
                v-model="calendarEventCustomersToCancel"
                :value="calendarEventCustomer"
                :aria-describedby="ariaDescribedby"
                :disabled="!calendarEventCustomer.customer.canCancelReservations"
              >
                {{ calendarEventCustomer.customer.lastName }}
                {{ calendarEventCustomer.customer.firstName }}
                <b-badge
                  v-if="isLateCancellationFinePossible(calendarEventCustomer.customer)"
                  variant="warning"
                >
                  {{ $t('calendar.event.cancellation-modal.late-cancellation-fine') }}
                </b-badge>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="calendarEventCustomersThatCanCancel.length === 1"
          class="mb-50"
        >
          <b-col>
            <b-alert
              :show="isLateCancellationFinePossible(calendarEventCustomersThatCanCancel[0].customer)"
              variant="danger"
              class="p-1 mt-1"
            >
              {{ $t('calendar.event.cancellation-modal.late-cancellation-fine-alert') }}
            </b-alert>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import store from '@/store'
import { parseRequestError } from '@/helpers/helpers'
import i18n from '@/libs/i18n'
import role from '@/auth/role'

require('cleave.js/dist/addons/cleave-phone.pl')

export default {
  props: {
    id: {
      type: String,
      required: false,
      default: 'cancel-event-modal-by-customer',
    },
    calendarEvent: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isShowingModal = ref(null)
    const processing = ref(null)
    const calendarEventCustomersToCancel = ref([])
    const service = computed(() => props.calendarEvent?.service)
    const calendarEventCustomersThatCanCancel = computed(() => {
      if (props.calendarEvent === null) {
        return []
      }

      return Object.values(props.calendarEvent?.calendarEventCustomers)
        .filter(
          calendarEventCustomer => calendarEventCustomer.customer.canCancelReservations,
        )
    })

    const init = () => {
      calendarEventCustomersToCancel.value = []
      if (calendarEventCustomersThatCanCancel.value.length === 1) {
        calendarEventCustomersToCancel.value = calendarEventCustomersThatCanCancel.value
      }
    }

    const isLateCancellationFinePossible = customer => {
      if (service.value?.priceType !== 'PerMeeting') {
        return false
      }

      if (customer.freeCancellationMinHours === 0) {
        return false
      }

      const freeCancellationTime = dayjs().add(customer.freeCancellationMinHours, 'hour')
      const eventStartAt = dayjs(props.calendarEvent.startAt)

      return freeCancellationTime.isAfter(eventStartAt)
    }

    const prepareCancelPromises = () => {
      const promises = []

      calendarEventCustomersToCancel.value.forEach(calendarEventCustomer => {
        const payload = {
          calendarEventId: props.calendarEvent.id,
          customerId: calendarEventCustomer.customer.id,
        }

        promises.push(store.dispatch('calendarEventEditing/cancelCalendarEvent', payload))
      })

      return promises
    }
    const cancelEvent = () => {
      processing.value = true
      Promise.all(prepareCancelPromises())
        .then(() => {
          emit('event-cancelled')
          window.toast.notify.success(i18n.t('calendar.event.form.alert.cancel.cancelled'))
          isShowingModal.value = false
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          processing.value = false
        })
    }

    return {
      isShowingModal,
      processing,
      role,

      init,
      cancelEvent,
      service,
      isLateCancellationFinePossible,
      calendarEventCustomersThatCanCancel,
      calendarEventCustomersToCancel,
    }
  },
}
</script>
