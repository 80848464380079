<template>
  <div>
    <cancel-event-by-customer-modal
      :calendar-event="calendarEventToCancel"
      @hidden="calendarEventToCancel = null"
      @event-cancelled="cancelledEventHandler"
    />

    <b-row>
      <b-col
        cols="12"
        xl="10"
        offset-xl="1"
      >

        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="8"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('shared.show') }}</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="fetchData"
                />
                <label>{{ $t('shared.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="4"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <flat-pickr
                    v-model="dateRangeString"
                    class="form-control"
                    :config="configDateTimePicker"
                  />

                  <b-form-input
                    v-if="false"
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    v-if="false"
                    variant="primary"
                    :to="{name: 'organization-script-add'}"
                  >
                    <span class="text-nowrap">{{ $t('script.scripts.btn.add') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <rc-overlay :show="loading">
            <b-table
              :key="`${currentPage}-meeting-list`"
              ref="refScriptListTable"
              primary-key="id"
              responsive
              hover
              :items="list"
              :fields="tableColumns"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
              show-empty
              :empty-text="loading ? '' : $t('shared.no-data')"
              @sort-changed="fetchData"
              @row-clicked="item => $router.push({name: 'customer-meeting-view', params: {id: item.id}})"
            >

              <!-- Column: Service -->
              <template #cell(service)="data">
                <b-link
                  variant="primary"
                  :to="{name: 'customer-meeting-view', params: {id: data.item.id}}"
                >
                  <strong
                    v-if="data.item.subject"
                    class="mr-1"
                  >
                    {{ data.item.subject }}
                  </strong>

                  <span>
                    <feather-icon
                      icon="ChevronsRightIcon"
                      class="mt-n25"
                    />
                    {{ data.item.service.name }}
                  </span>
                </b-link>
              </template>

              <!-- Column: Time -->
              <template #cell(startAt)="data">
                <div class="text-right">
                  <span class="text-secondary">
                    {{ transformDateToHumanReadablePretty(data.item.startAt) }}
                  </span>
                  <strong class="text-nowrap">
                    {{ transformTimeToHumanReadablePretty(data.item.startAt) }}
                    - {{ transformTimeToHumanReadablePretty(data.item.endAt) }}
                  </strong>
                </div>
              </template>

              <!-- Column: Attendees -->
              <template #cell(attendees)="data">
                <span
                  class="text-secondary"
                  v-html="Object.values(data.item.calendarEventCustomers).map(c => `${c.customer.lastName} ${c.customer.firstName}`).join(',<br>')"
                />
              </template>

              <!-- Column: Educator -->
              <template #cell(educator)="data">
                <div class="text-right">
                  <span class="text-secondary">
                    {{ data.item.calendar.owner.lastName }} {{ data.item.calendar.owner.firstName }}
                  </span>
                </div>
              </template>

              <!-- Column: Payment status -->
              <template #cell(payment)="data">
                <div
                  v-if="getPaymentStatus(data.item).total"
                  class="text-right"
                >
                  <span
                    v-if="getPaymentStatus(data.item).paid === getPaymentStatus(data.item).total "
                    v-b-tooltip.hover.v-success
                    class="font-weight-bold text-success"
                    :title="$t('meeting.attendees.status-paid')"
                  >
                    {{ getPaymentStatus(data.item).total }} zł
                  </span>
                  <span
                    v-else-if="getPaymentStatus(data.item).paid === 0"
                    v-b-tooltip.hover.v-danger
                    class="font-weight-bold text-danger"
                    :title="$t('meeting.attendees.status-not-paid')"
                  >
                    {{ getPaymentStatus(data.item).total }} zł
                  </span>
                  <span
                    v-else
                    v-b-tooltip.hover.v-warning
                    class="font-weight-bold text-warning"
                    :title="$t('meeting.attendees.status-partially-paid')"
                  >
                    {{ getPaymentStatus(data.item).paid }} / {{ getPaymentStatus(data.item).total }} zł
                  </span>
                  <div
                    v-if="data.item.service.priceType && data.item.service.priceType !== 'PerMeeting'"
                    class="text-nowrap font-small-2"
                  >
                    {{ $t(`shared.price-types.${data.item.service.priceType}`).toLowerCase() }}
                  </div>
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown variant="flat-primary">
                  <template #button-content>
                    <feather-icon icon="SettingsIcon" />
                  </template>
                  <b-dropdown-item @click="$router.push({name: 'customer-meeting-view', params: {id: data.item.id}})">
                    <feather-icon
                      icon="SearchIcon"
                      size="14"
                      class="mr-25"
                    />
                    {{ $t('meeting.list.view') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isPossibleToCancel(data.item)"
                    @click="cancelMeetingHandler(data.item)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="14"
                      class="mr-25"
                    />
                    {{ $t('meeting.list.cancel') }}
                  </b-dropdown-item>
                  <!--                  <b-dropdown-divider />-->
                </b-dropdown>
              </template>

            </b-table>
          </rc-overlay>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('shared.pagination-summary', dataMeta) }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-if="totalItems > perPage"
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="fetchData"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import listSetup from '@/views/customer/meeting/listSetup'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import role from '@/auth/role'
import CancelEventByCustomerModal from '@/views/_global/CancelEventByCustomerModal.vue'

export default {
  components: {
    CancelEventByCustomerModal,
    RcOverlay,
    flatPickr,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
    tableColumns() {
      const columns = [
        {
          key: 'service',
          label: this.$t('customer.meeting.service'),
          sortable: false,
        },
        {
          key: 'startAt',
          label: this.$t('customers.tabs.settlements.event-time'),
          sortable: true,
        },
        // { key: 'actions', label: this.$t('shared.actions'), sortable: false },
      ]

      if (this.me.roles.includes(role.guardian)) {
        columns.push({
          key: 'attendees',
          label: this.$t('meeting.attendees.title'),
          sortable: false,
        })
      }

      columns.push({
        key: 'educator',
        label: this.$t('shared.educator'),
        sortable: false,
      })
      columns.push({
        key: 'payment',
        label: this.$t('meeting.attendees.payment'),
        sortable: false,
      })
      columns.push({
        key: 'actions',
        label: this.$t('meeting.attendees.actions'),
        sortable: false,
      })

      return columns
    },
  },
  setup: listSetup,
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
